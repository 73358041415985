import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`SocialButton`}</h1>
    <p>{`To implement SocialButton component into your project you’ll need to add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import SocialButton from "@kiwicom/orbit-components/lib/SocialButton";
`}</code></pre>
    <p>{`After adding import into your project you can use it simply like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SocialButton>Hello World!</SocialButton>
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Table below contains all types of the props available in SocialButton component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ariaControls`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Id of the element the button controls.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ariaExpanded`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tells screen reader the controlled element from `}<inlineCode parentName="td">{`ariaControls`}</inlineCode>{` is expanded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`asComponent`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string \\| React.Element`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"button"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The component used for the root node.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`fullWidth`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, the SocialButton will grow up to the full width of its container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The content of the SocialButton. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set `}<inlineCode parentName="td">{`id`}</inlineCode>{` for `}<inlineCode parentName="td">{`SocialButton`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, the SocialButton will be disabled.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`external`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, the SocialButton opens link in a new tab. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The URL of the link to open when SocialButton is clicked. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`loading`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, the loading glyph will be displayed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onClick`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`event => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function for handling onClick event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ref`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Prop for forwarded ref of the SocialButton.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`role`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specifies the role of an element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`size`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#enum"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"normal"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The size of the SocialButton.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`spaceAfter`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`enum`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Additional `}<inlineCode parentName="td">{`margin-bottom`}</inlineCode>{` after component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`submit`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, the SocialButton will have `}<inlineCode parentName="td">{`type="submit"`}</inlineCode>{` attribute, otherwise `}<inlineCode parentName="td">{`type="button"`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tabIndex`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string \\| number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specifies the tab order of an element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Adds `}<inlineCode parentName="td">{`aria-label`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`type`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#enum"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"apple"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The type of SocialButton.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The width of the SocialButton. Can be any string - `}<inlineCode parentName="td">{`100px`}</inlineCode>{`, `}<inlineCode parentName="td">{`20%`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`enum`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`size`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`spaceAfter`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"apple"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"small"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"none"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"facebook"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"normal"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"smallest"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"google"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"large"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"small"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"twitter"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"normal"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"email"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"medium"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"large"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"largest"`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Functional specs`}</h2>
    <ul>
      <li parentName="ul">{`When the `}<inlineCode parentName="li">{`external`}</inlineCode>{` is specified, `}<inlineCode parentName="li">{`noopener`}</inlineCode>{` value will be automatically added to attribute `}<inlineCode parentName="li">{`rel`}</inlineCode>{` for security reason.`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`By passing the `}<inlineCode parentName="li">{`href`}</inlineCode>{` prop into SocialButton, it will render into `}<inlineCode parentName="li">{`a`}</inlineCode>{` element. If you pass `}<inlineCode parentName="li">{`asComponent`}</inlineCode>{` prop it will override this logic.`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If you want to use the `}<inlineCode parentName="p">{`asComponent`}</inlineCode>{` prop then `}<strong parentName="p">{`YourComponent`}</strong>{` should accept at least `}<inlineCode parentName="p">{`className`}</inlineCode>{`. Otherwise it won’t be rendered with proper styling, e.g.:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`const YourComponent = props => <div {...props} />

<SocialButton asComponent={YourComponent}>Title</SocialButton>
`}</code></pre>
        <p parentName="li">{`If you specify the children of `}<strong parentName="p">{`YourComponent`}</strong>{` component, it will override the children prop of SocialButton component, e.g.:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`const YourComponent = props => <div {...props}>YourComponent</div>;
`}</code></pre>
      </li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <p>{`A button is mainly used for indicating an action, submitting a data, opening a modal etc. If you want to use SocialButton for navigation consider using a `}<inlineCode parentName="p">{`<TextLink>`}</inlineCode>{` for that.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Use `}<inlineCode parentName="p">{`ariaControls`}</inlineCode>{` prop to add `}<inlineCode parentName="p">{`aria-controls`}</inlineCode>{` attribute to establish the relationship between button and element which is controlled by it. `}<inlineCode parentName="p">{`aria-controls`}</inlineCode>{` works only with a unique `}<inlineCode parentName="p">{`id`}</inlineCode>{` of an element.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use `}<inlineCode parentName="p">{`ariaExpands`}</inlineCode>{` prop to add `}<inlineCode parentName="p">{`aria-expands`}</inlineCode>{` to indicate screenreaders, that element controlled by button through `}<inlineCode parentName="p">{`ariaControls`}</inlineCode>{` is expanded or not.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop to indicate users that button is inactive and they can’t interact with it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use `}<inlineCode parentName="p">{`role`}</inlineCode>{` and `}<inlineCode parentName="p">{`tabIndex`}</inlineCode>{` when you are rendering `}<inlineCode parentName="p">{`SocialButton`}</inlineCode>{` to non-actionable HTML element as `}<inlineCode parentName="p">{`div`}</inlineCode>{` or `}<inlineCode parentName="p">{`span`}</inlineCode>{`. However, this should be done only in edge-cases as it is anti-pattern behavior.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use `}<inlineCode parentName="p">{`title`}</inlineCode>{` to add `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` when you need to add extra informations to screen readers or there is no `}<inlineCode parentName="p">{`children`}</inlineCode>{` presented to be used as label.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      