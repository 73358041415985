import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`@kiwicom/eslint-plugin-orbit-components`}</h1>
    <p>{`Collection of ESLint rules to enforce the best usage practices of `}<a parentName="p" {...{
        "href": "https://github.com/kiwicom/orbit-components/"
      }}><inlineCode parentName="a">{`@kiwicom/orbit-components`}</inlineCode></a>{`.`}</p>
    <h2>{`Installation`}</h2>
    <p>{`Assuming you already have ESLint installed, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`# npm
npm install @kiwicom/eslint-plugin-orbit-components --save-dev

# yarn
yarn add @kiwicom/eslint-plugin-orbit-components --dev
`}</code></pre>
    <p>{`Then add it to your ESLint configuration:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "plugins": [
    // ...
    "@kiwicom/eslint-plugin-orbit-components"
  ],
  "rules": {
    // ...
    "@kiwicom/orbit-components/button-has-title": "error",
    "@kiwicom/orbit-components/unnecessary-text": "error",
  }
}
`}</code></pre>
    <p>{`or via extending our recommended config:`}</p>
    <pre><code parentName="pre" {...{}}>{`  {
    "extends": [
      "plugin:orbit-components/recommended",
    ]
  }
`}</code></pre>
    <h2>{`Supported Rules`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/blob/master/packages/eslint-plugin-orbit-components/docs/rules/button-has-title.md"
        }}>{`button-has-title`}</a>{`: Enforces that `}<inlineCode parentName="li">{`Button`}</inlineCode>{` and `}<inlineCode parentName="li">{`ButtonLink`}</inlineCode>{` do have either `}<inlineCode parentName="li">{`children`}</inlineCode>{` or `}<inlineCode parentName="li">{`title`}</inlineCode>{` property.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/blob/master/packages/eslint-plugin-orbit-components/docs/rules/unnecessary-text.md"
        }}>{`unnecessary-text`}</a>{`: Enforces that children of `}<inlineCode parentName="li">{`Button`}</inlineCode>{` or `}<inlineCode parentName="li">{`Heading`}</inlineCode>{` are not wrapped in `}<inlineCode parentName="li">{`Text`}</inlineCode>{` component.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/blob/master/packages/eslint-plugin-orbit-components/docs/rules/default-theme.md"
        }}>{`default-theme`}</a>{`:
This rule aims to prevent the wrong usage of the defaultTheme object from @kiwicom/orbit-components`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/blob/master/packages/eslint-plugin-orbit-components/docs/rules/no-custom-colors.md"
        }}>{`no-custom-colors`}</a>{`:
Prevents inconsistencies between Orbit and custom colors`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/blob/master/packages/eslint-plugin-orbit-components/docs/rules/no-custom-typography.md"
        }}>{`no-custom-typography`}</a>{`: This rule aims to prevent the usage of custom values for font-size, font-family and line-height CSS properties. Only some of the design tokens from @kiwicom/orbit-design-tokens should be used as a value.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/blob/master/packages/eslint-plugin-orbit-components/docs/rules/prefer-single-destructure.md"
        }}>{`prefer-single-destructure`}</a>{`: Using too many arrow functions in interpolations can harm performance - because they have to be evaluated with execution context. This is done internally by wrapping all functions into the css helper from styled-components`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/blob/master/packages/eslint-plugin-orbit-components/docs/rules/rtl-utils.md"
        }}>{`rtl-utils`}</a>{`:
Prevents bad theme.rtl patterns. Users often make the same mistake, they tend to use `}<inlineCode parentName="li">{`theme.rtl`}</inlineCode>{` to apply RTL styles like right/left position, margins, and paddings. This rule should prevent such cases and enforce the usage of our RTL utility functions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/blob/master/packages/eslint-plugin-orbit-components/docs/rules/unique-id.md"
        }}>{`unique-id`}</a>{`:
Rule prevents namespace collisions of the `}<inlineCode parentName="li">{`id`}</inlineCode>{` HTML attribute. It often happens, that some elements can have the same ids. To prevent it in our codebase, it’s better to use `}<inlineCode parentName="li">{`randomID`}</inlineCode>{` utility function.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/blob/master/packages/eslint-plugin-orbit-components/docs/rules/use-rtl.md"
        }}>{`use-rtl`}</a>{`:
This rule aims to prevent RTL mistakes. A user can forget about RTL and use only static values, this rule should help to avoid that.`}</li>
    </ul>
    <h2>{`License`}</h2>
    <p>{`MIT`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      