import { parseToRgba, guard, rgba } from 'color2k';

// src/js/defaultFoundation.ts
var blue = {
  dark: "#005AA3",
  darkActive: "#003E70",
  darkHover: "#004F8F",
  darker: "#004680",
  light: "#E8F4FD",
  lightActive: "#C7E4FA",
  lightHover: "#DEF0FC",
  normal: "#0172CB",
  normalActive: "#01508E",
  normalHover: "#0161AC"
};
var bundle = { basic: "#D7331C", medium: "#3B1EB0" };
var cloud = {
  dark: "#BAC7D5",
  darkActive: "#94A8BE",
  darkHover: "#A6B6C8",
  light: "#F5F7F9",
  lightActive: "#D6DEE6",
  lightHover: "#E5EAEF",
  normal: "#E8EDF1",
  normalActive: "#CAD4DE",
  normalHover: "#DCE3E9"
};
var green = {
  dark: "#2D7738",
  darkActive: "#1F5126",
  darkHover: "#276831",
  darker: "#235C2B",
  light: "#EAF5EA",
  lightActive: "#CDE4CF",
  lightHover: "#E1EFE2",
  normal: "#28A138",
  normalActive: "#1D7228",
  normalHover: "#238B31"
};
var ink = {
  dark: "#252A31",
  darkActive: "#0B0C0F",
  darkHover: "#181B20",
  light: "#697D95",
  lightActive: "#4A617C",
  lightHover: "#5D738E",
  normal: "#4F5E71",
  normalActive: "#324256",
  normalHover: "#3E4E63"
};
var orange = {
  dark: "#AD5700",
  darkActive: "#954A00",
  darkHover: "#A75400",
  darker: "#803F00",
  light: "#FEF2E6",
  lightActive: "#FAE2C6",
  lightHover: "#FCECDA",
  normal: "#DF7B00",
  normalActive: "#B26200",
  normalHover: "#C96F00"
};
var product = {
  dark: "#007A69",
  darkActive: "#006657",
  darkHover: "#007060",
  darker: "#005C4E",
  light: "#E1F4F3",
  lightActive: "#BFE8E2",
  lightHover: "#D6F0EC",
  normal: "#00A58E",
  normalActive: "#008472",
  normalHover: "#009580"
};
var red = {
  dark: "#970C0C",
  darkActive: "#6D0909",
  darkHover: "#890B0B",
  darker: "#760909",
  light: "#FAEAEA",
  lightActive: "#F3CECE",
  lightHover: "#F8E2E2",
  normal: "#D21C1C",
  normalActive: "#9D1515",
  normalHover: "#B91919"
};
var social = { facebook: "#3B5998", facebookHover: "#385490", facebookActive: "#354F88" };
var white = { normal: "#FFFFFF", normalActive: "#E7ECF1", normalHover: "#F1F4F7" };
var borderRadius = {
  50: "2px",
  100: "4px",
  150: "6px",
  300: "12px",
  400: "16px",
  circle: "50%",
  none: "0",
  small: "2px",
  normal: "3px",
  large: "6px",
  full: "9999px"
};
var breakpoint = {
  smallMobile: 320,
  mediumMobile: 414,
  largeMobile: 576,
  tablet: 768,
  desktop: 992,
  largeDesktop: 1200
};
var size = {
  small: "16px",
  medium: "24px",
  large: "32px",
  extraLarge: "44px",
  extraExtraLarge: "52px"
};
var space = {
  50: "2px",
  100: "4px",
  150: "6px",
  200: "8px",
  300: "12px",
  400: "16px",
  500: "20px",
  600: "24px",
  800: "32px",
  1e3: "40px",
  1200: "48px",
  1600: "64px",
  XXXSmall: "2px",
  XXSmall: "4px",
  XSmall: "8px",
  small: "12px",
  medium: "16px",
  large: "24px",
  XLarge: "32px",
  XXLarge: "40px",
  XXXLarge: "52px"
};
var fontFamily = {
  base: "'Roboto', -apple-system, '.SFNSText-Regular', 'San Francisco', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif"
};
var fontSize = { small: "13px", normal: "15px", large: "16px", extraLarge: "18px" };
var lineHeight = { small: "16px", normal: "20px", large: "24px", extraLarge: "24px" };
var fontWeight = { normal: "400", medium: "500", bold: "700" };
var palette = { blue, bundle, cloud, green, ink, orange, product, red, social, white };
var foundation = {
  palette,
  borderRadius,
  breakpoint,
  size,
  space,
  fontFamily,
  fontSize,
  lineHeight,
  fontWeight
};
var defaultFoundation_default = foundation;
var isHex = (color) => color.startsWith("#");
var isRgb = (color) => color.startsWith("rgb");
var transparentColor = (color, opacity) => {
  if (color.match(/var\(/)) {
    const regex = /var\(([^,]+),\s*([^)]+)\)/;
    const [, , colorValue] = color.match(regex);
    const value = colorValue;
    const isValidColor = isHex(value) || isRgb(value);
    return transparentColor(isValidColor ? value : `rgb(${value})`, opacity);
  }
  const [R, G, B] = parseToRgba(color);
  const A = guard(0, 100, opacity) / 100;
  return rgba(R, G, B, A);
};
var transparentColor_default = transparentColor;

// src/js/boxShadow.ts
var boxShadow = (definitions) => (Array.isArray(definitions) ? definitions : [definitions]).map(
  ({ def, color, inset = false }) => [inset ? "inset" : null, ...def.slice(0, 4), color].filter(Boolean).join(" ")
).join(",");
var boxShadow_default = boxShadow;

// src/js/createTokens.ts
var createTokens = (foundation2) => ({
  paddingAlert: foundation2.space.medium,
  paddingAlertWithIcon: foundation2.space.small,
  paddingBadge: `0 ${foundation2.space.XSmall}`,
  paddingButtonLarge: `0 28px`,
  paddingButtonLargeWithIcons: `0 ${foundation2.space.medium}`,
  paddingButtonLargeWithLeftIcon: `0 28px 0 ${foundation2.space.medium}`,
  paddingButtonLargeWithRightIcon: `0 ${foundation2.space.medium} 0 28px`,
  paddingButtonNormal: `0 ${foundation2.space.medium}`,
  paddingButtonNormalWithIcons: `0 ${foundation2.space.small}`,
  paddingButtonNormalWithLeftIcon: `0 ${foundation2.space.medium} 0 ${foundation2.space.small}`,
  paddingButtonNormalWithRightIcon: `0 ${foundation2.space.small} 0 ${foundation2.space.medium}`,
  paddingButtonWithoutText: "0",
  paddingButtonSmall: `0 ${foundation2.space.small}`,
  paddingButtonSmallWithIcons: `0 ${foundation2.space.XSmall}`,
  paddingButtonSmallWithLeftIcon: `0 ${foundation2.space.small} 0 ${foundation2.space.XSmall}`,
  paddingButtonSmallWithRightIcon: `0 ${foundation2.space.XSmall} 0 ${foundation2.space.small}`,
  paddingInputSmall: `0 ${foundation2.space.small}`,
  paddingInputNormal: `0 ${foundation2.space.small}`,
  paddingInputFile: `0 0 0 6px`,
  paddingLoading: foundation2.space.small,
  paddingTable: `${foundation2.space.small} ${foundation2.space.medium}`,
  paddingTableCompact: `${foundation2.space.XSmall} ${foundation2.space.small}`,
  paddingTag: `6px ${foundation2.space.XSmall}`,
  paddingTagRemovable: `6px 6px 6px ${foundation2.space.XSmall}`,
  paddingTagRemovableWithIcon: "6px",
  paddingTagWithIcon: `6px ${foundation2.space.XSmall} 6px 6px`,
  paddingTextareaSmall: `${foundation2.space.XSmall} ${foundation2.space.small}`,
  paddingTextareaNormal: foundation2.space.small,
  backgroundAlertCritical: foundation2.palette.red.light,
  backgroundAlertInfo: foundation2.palette.blue.light,
  backgroundAlertSuccess: foundation2.palette.green.light,
  backgroundAlertWarning: foundation2.palette.orange.light,
  backgroundBadgeCritical: foundation2.palette.red.normal,
  backgroundBadgeCriticalSubtle: foundation2.palette.red.light,
  backgroundBadgeDark: foundation2.palette.ink.dark,
  backgroundBadgeInfo: foundation2.palette.blue.normal,
  backgroundBadgeInfoSubtle: foundation2.palette.blue.light,
  backgroundBadgeNeutral: foundation2.palette.cloud.light,
  backgroundBadgeSuccess: foundation2.palette.green.normal,
  backgroundBadgeSuccessSubtle: foundation2.palette.green.light,
  backgroundBadgeWarning: foundation2.palette.orange.normal,
  backgroundBadgeWarningSubtle: foundation2.palette.orange.light,
  backgroundBadgeWhite: foundation2.palette.white.normal,
  backgroundButtonLinkCritical: "transparent",
  backgroundButtonLinkCriticalHover: foundation2.palette.red.lightHover,
  backgroundButtonLinkCriticalActive: foundation2.palette.red.lightActive,
  backgroundButtonLinkPrimary: "transparent",
  backgroundButtonLinkPrimaryHover: foundation2.palette.product.lightHover,
  backgroundButtonLinkPrimaryActive: foundation2.palette.product.lightActive,
  backgroundButtonLinkSecondary: "transparent",
  backgroundButtonLinkSecondaryHover: foundation2.palette.cloud.lightHover,
  backgroundButtonLinkSecondaryActive: foundation2.palette.cloud.lightActive,
  backgroundButtonBundleBasic: "linear-gradient(to top right, #E13E3B 0%, #E87E09 100%)",
  backgroundButtonBundleBasicHover: "linear-gradient(to top right, #BD2825 0%, #D67000 100%)",
  backgroundButtonBundleBasicActive: "linear-gradient(to top right, #9F1816 0%, #C36802 100%)",
  backgroundButtonBundleMedium: "linear-gradient(to top right, #3719AB 0%, #8539DB 100%)",
  backgroundButtonBundleMediumHover: "linear-gradient(to top right, #2D1393 0%, #7343AA 100%)",
  backgroundButtonBundleMediumActive: "linear-gradient(to top right, #250F79 0%, #5A3485 100%)",
  backgroundButtonBundleTop: "linear-gradient(to top right, #2D2D2E 0%, #696E73 100%)",
  backgroundButtonBundleTopHover: "linear-gradient(to top right, #171718 0%, #51575C 100%)",
  backgroundButtonBundleTopActive: "linear-gradient(to top right, #101011 0%, #51575C)",
  backgroundButtonCriticalSubtle: foundation2.palette.red.light,
  backgroundButtonCriticalSubtleHover: foundation2.palette.red.lightHover,
  backgroundButtonCriticalSubtleActive: foundation2.palette.red.lightActive,
  backgroundButtonCriticalSubtleFocus: foundation2.palette.red.light,
  backgroundButtonCritical: foundation2.palette.red.normal,
  backgroundButtonCriticalHover: foundation2.palette.red.normalHover,
  backgroundButtonCriticalActive: foundation2.palette.red.normalActive,
  backgroundButtonBordered: "transparent",
  backgroundButtonBorderedHover: foundation2.palette.cloud.light,
  backgroundButtonBorderedActive: foundation2.palette.cloud.light,
  backgroundButtonInfo: foundation2.palette.blue.normal,
  backgroundButtonInfoHover: foundation2.palette.blue.normalHover,
  backgroundButtonInfoActive: foundation2.palette.blue.normalActive,
  backgroundButtonPrimarySubtle: foundation2.palette.product.light,
  backgroundButtonPrimarySubtleHover: foundation2.palette.product.lightHover,
  backgroundButtonPrimarySubtleActive: foundation2.palette.product.lightActive,
  backgroundButtonPrimarySubtleFocus: foundation2.palette.product.light,
  backgroundButtonPrimary: foundation2.palette.product.normal,
  backgroundButtonPrimaryHover: foundation2.palette.product.normalHover,
  backgroundButtonPrimaryActive: foundation2.palette.product.normalActive,
  backgroundButtonSecondary: foundation2.palette.cloud.normal,
  backgroundButtonSecondaryHover: foundation2.palette.cloud.normalHover,
  backgroundButtonSecondaryActive: foundation2.palette.cloud.normalActive,
  backgroundButtonSuccess: foundation2.palette.green.normal,
  backgroundButtonSuccessHover: foundation2.palette.green.normalHover,
  backgroundButtonSuccessActive: foundation2.palette.green.normalActive,
  backgroundButtonWarning: foundation2.palette.orange.normal,
  backgroundButtonWarningHover: foundation2.palette.orange.normalHover,
  backgroundButtonWarningActive: foundation2.palette.orange.normalActive,
  backgroundButtonWhite: foundation2.palette.white.normal,
  backgroundButtonWhiteHover: foundation2.palette.cloud.light,
  backgroundButtonWhiteActive: foundation2.palette.cloud.lightHover,
  backgroundButtonWhiteBordered: "transparent",
  backgroundButtonWhiteBorderedHover: transparentColor_default(foundation2.palette.white.normal, 20),
  backgroundButtonWhiteBorderedActive: transparentColor_default(foundation2.palette.white.normal, 20),
  backgroundButtonFacebook: "#3B5998",
  backgroundButtonFacebookHover: "#385490",
  backgroundButtonFacebookActive: "#354F88",
  backgroundButtonGoogle: foundation2.palette.cloud.light,
  backgroundButtonGoogleHover: foundation2.palette.cloud.lightHover,
  backgroundButtonGoogleActive: foundation2.palette.cloud.lightActive,
  backgroundCard: foundation2.palette.white.normal,
  backgroundCarrierLogo: "transparent",
  backgroundCountryFlag: "transparent",
  backgroundServiceLogo: "transparent",
  backgroundTooltip: foundation2.palette.white.normal,
  backgroundTooltipLargeMobile: foundation2.palette.blue.dark,
  backgroundSeparator: foundation2.palette.cloud.normal,
  backgroundSwitch: foundation2.palette.cloud.dark,
  backgroundSwitchChecked: foundation2.palette.blue.normal,
  backgroundInput: foundation2.palette.white.normal,
  backgroundInputDisabled: foundation2.palette.cloud.normal,
  backgroundModal: foundation2.palette.white.normal,
  backgroundTable: foundation2.palette.white.normal,
  backgroundTableEven: foundation2.palette.cloud.light,
  backgroundTableHover: foundation2.palette.cloud.normal,
  backgroundTableShadowLeft: "linear-gradient(to left, transparent, rgba(186, 199, 213, 23))",
  backgroundTableShadowRight: "linear-gradient(to right, transparent, rgba(186, 199, 213, 23))",
  backgroundTag: foundation2.palette.cloud.light,
  backgroundTagHover: foundation2.palette.cloud.lightHover,
  backgroundTagActive: foundation2.palette.cloud.lightActive,
  backgroundTagSelected: foundation2.palette.ink.dark,
  backgroundTagSelectedHover: foundation2.palette.ink.darkHover,
  backgroundTagSelectedActive: foundation2.palette.ink.darkActive,
  colorAlertIconCritical: foundation2.palette.red.normal,
  colorAlertIconInfo: foundation2.palette.blue.normal,
  colorAlertIconSuccess: foundation2.palette.green.normal,
  colorAlertIconWarning: foundation2.palette.orange.normal,
  colorFormLabel: foundation2.palette.ink.dark,
  colorFormLabelFilled: foundation2.palette.ink.normal,
  colorIconCheckboxRadio: foundation2.palette.product.normal,
  colorIconCheckboxRadioDisabled: foundation2.palette.cloud.dark,
  colorIconInput: foundation2.palette.cloud.dark,
  colorIconRadioButton: foundation2.palette.product.normal,
  colorIconRadioButtonDisabled: foundation2.palette.ink.normal,
  colorInfoCheckBoxRadio: foundation2.palette.ink.normal,
  colorPlaceholderInput: foundation2.palette.ink.light,
  colorPlaceholderInputError: foundation2.palette.red.normal,
  colorPlaceholderInputFile: foundation2.palette.ink.light,
  colorPlaceholderInputFileError: foundation2.palette.red.normal,
  colorTextInput: foundation2.palette.ink.dark,
  colorTextInputDisabled: foundation2.palette.ink.normal,
  colorTextInputPrefix: foundation2.palette.ink.normal,
  colorHeading: foundation2.palette.ink.dark,
  colorHeadingInverted: foundation2.palette.white.normal,
  colorIconPrimary: foundation2.palette.ink.dark,
  colorIconSecondary: foundation2.palette.ink.normal,
  colorIconTertiary: foundation2.palette.cloud.dark,
  colorIconInfo: foundation2.palette.blue.normal,
  colorIconSuccess: foundation2.palette.green.normal,
  colorIconWarning: foundation2.palette.orange.normal,
  colorIconCritical: foundation2.palette.red.normal,
  colorStopoverArrow: foundation2.palette.ink.light,
  colorTextLinkPrimary: foundation2.palette.product.dark,
  colorTextLinkPrimaryHover: foundation2.palette.product.normal,
  colorTextLinkSecondary: foundation2.palette.ink.dark,
  colorTextLinkSecondaryHover: foundation2.palette.product.normal,
  colorTextPrimary: foundation2.palette.ink.dark,
  colorTextSecondary: foundation2.palette.ink.normal,
  colorTextInfo: foundation2.palette.blue.normal,
  colorTextSuccess: foundation2.palette.green.normal,
  colorTextWarning: foundation2.palette.orange.normal,
  colorTextCritical: foundation2.palette.red.normal,
  colorTextWhite: foundation2.palette.white.normal,
  colorTextError: foundation2.palette.red.normal,
  colorTextAlertCritical: foundation2.palette.red.dark,
  colorTextAlertInfo: foundation2.palette.blue.dark,
  colorTextAlertSuccess: foundation2.palette.green.dark,
  colorTextAlertWarning: foundation2.palette.orange.dark,
  colorTextAlertLink: foundation2.palette.ink.normal,
  colorTextBadgeCritical: foundation2.palette.red.normal,
  colorTextBadgeDark: foundation2.palette.white.normal,
  colorTextBadgeInfo: foundation2.palette.blue.normal,
  colorTextBadgeNeutral: foundation2.palette.ink.normal,
  colorTextBadgeSuccess: foundation2.palette.green.normal,
  colorTextBadgeWarning: foundation2.palette.orange.normal,
  colorTextBadgeWhite: foundation2.palette.ink.dark,
  colorTextButtonLinkCritical: foundation2.palette.red.normal,
  colorTextButtonLinkCriticalHover: foundation2.palette.red.normalHover,
  colorTextButtonLinkCriticalActive: foundation2.palette.red.normalActive,
  colorTextButtonLinkPrimary: foundation2.palette.product.normal,
  colorTextButtonLinkPrimaryHover: foundation2.palette.product.normalHover,
  colorTextButtonLinkPrimaryActive: foundation2.palette.product.normalActive,
  colorTextButtonLinkSecondary: foundation2.palette.ink.dark,
  colorTextButtonLinkSecondaryHover: foundation2.palette.ink.darkHover,
  colorTextButtonLinkSecondaryActive: foundation2.palette.ink.darkActive,
  colorTextButtonLinkSecondaryCompactHover: foundation2.palette.product.normalHover,
  colorTextButtonLinkSecondaryCompactActive: foundation2.palette.product.normalActive,
  colorTextButtonCriticalSubtle: foundation2.palette.red.dark,
  colorTextButtonCriticalSubtleHover: foundation2.palette.red.darkHover,
  colorTextButtonCriticalSubtleActive: foundation2.palette.red.darkActive,
  colorTextButtonCritical: foundation2.palette.white.normal,
  colorTextButtonCriticalHover: foundation2.palette.white.normal,
  colorTextButtonCriticalActive: foundation2.palette.white.normal,
  colorTextButtonCriticalBordered: foundation2.palette.red.normal,
  colorTextButtonCriticalBorderedHover: foundation2.palette.red.normalHover,
  colorTextButtonCriticalBorderedActive: foundation2.palette.red.normalActive,
  colorTextButtonFilled: foundation2.palette.white.normal,
  colorTextButtonFilledHover: foundation2.palette.white.normal,
  colorTextButtonFilledActive: foundation2.palette.white.normal,
  colorTextButtonInfo: foundation2.palette.white.normal,
  colorTextButtonInfoHover: foundation2.palette.white.normal,
  colorTextButtonInfoActive: foundation2.palette.white.normal,
  colorTextButtonInfoBordered: foundation2.palette.blue.normal,
  colorTextButtonInfoBorderedHover: foundation2.palette.blue.normalHover,
  colorTextButtonInfoBorderedActive: foundation2.palette.blue.normalActive,
  colorTextButtonPrimarySubtle: foundation2.palette.product.dark,
  colorTextButtonPrimarySubtleHover: foundation2.palette.product.darkHover,
  colorTextButtonPrimarySubtleActive: foundation2.palette.product.darkActive,
  colorTextButtonPrimary: foundation2.palette.white.normal,
  colorTextButtonPrimaryHover: foundation2.palette.white.normal,
  colorTextButtonPrimaryActive: foundation2.palette.white.normal,
  colorTextButtonPrimaryBordered: foundation2.palette.product.normal,
  colorTextButtonPrimaryBorderedHover: foundation2.palette.product.normalHover,
  colorTextButtonPrimaryBorderedActive: foundation2.palette.product.normalActive,
  colorTextButtonSecondary: foundation2.palette.ink.dark,
  colorTextButtonSecondaryHover: foundation2.palette.ink.darkHover,
  colorTextButtonSecondaryActive: foundation2.palette.ink.darkActive,
  colorTextButtonSecondaryBordered: foundation2.palette.ink.dark,
  colorTextButtonSecondaryBorderedHover: foundation2.palette.ink.darkHover,
  colorTextButtonSecondaryBorderedActive: foundation2.palette.ink.darkActive,
  colorTextButtonSuccess: foundation2.palette.white.normal,
  colorTextButtonSuccessHover: foundation2.palette.white.normal,
  colorTextButtonSuccessActive: foundation2.palette.white.normal,
  colorTextButtonSuccessBordered: foundation2.palette.green.normal,
  colorTextButtonSuccessBorderedHover: foundation2.palette.green.normalHover,
  colorTextButtonSuccessBorderedActive: foundation2.palette.green.normalActive,
  colorTextButtonWarning: foundation2.palette.white.normal,
  colorTextButtonWarningHover: foundation2.palette.white.normal,
  colorTextButtonWarningActive: foundation2.palette.white.normal,
  colorTextButtonWarningBordered: foundation2.palette.orange.normal,
  colorTextButtonWarningBorderedHover: foundation2.palette.orange.normalHover,
  colorTextButtonWarningBorderedActive: foundation2.palette.orange.normalActive,
  colorTextButtonWhite: foundation2.palette.ink.dark,
  colorTextButtonWhiteHover: foundation2.palette.ink.darkHover,
  colorTextButtonWhiteActive: foundation2.palette.ink.darkActive,
  colorTextButtonWhiteBordered: foundation2.palette.white.normal,
  colorTextButtonWhiteBorderedHover: foundation2.palette.white.normal,
  colorTextButtonWhiteBorderedActive: foundation2.palette.white.normal,
  colorTextButtonFacebook: foundation2.palette.white.normal,
  colorTextButtonFacebookHover: foundation2.palette.white.normal,
  colorTextButtonFacebookActive: foundation2.palette.white.normal,
  colorTextButtonFacebookBordered: "#3B5998",
  colorTextButtonFacebookBorderedHover: "#385490",
  colorTextButtonFacebookBorderedActive: "#354F88",
  colorTextButtonGoogle: foundation2.palette.ink.dark,
  colorTextButtonGoogleHover: foundation2.palette.ink.darkHover,
  colorTextButtonGoogleActive: foundation2.palette.ink.darkActive,
  colorTextButtonGoogleBordered: foundation2.palette.ink.dark,
  colorTextButtonGoogleBorderedHover: foundation2.palette.ink.darkHover,
  colorTextButtonGoogleBorderedActive: foundation2.palette.ink.darkActive,
  colorTextLoading: foundation2.palette.cloud.dark,
  colorTextTable: foundation2.palette.ink.light,
  colorTextTag: foundation2.palette.ink.dark,
  colorTextTagSelected: foundation2.palette.cloud.dark,
  alertBackgroundCritical: foundation2.palette.red.light,
  alertBackgroundInfo: foundation2.palette.blue.light,
  alertBackgroundSuccess: foundation2.palette.green.light,
  alertBackgroundWarning: foundation2.palette.orange.light,
  alertIconCritical: foundation2.palette.red.normal,
  alertIconInfo: foundation2.palette.blue.normal,
  alertIconSuccess: foundation2.palette.green.normal,
  alertIconWarning: foundation2.palette.orange.normal,
  alertColorTextCritical: foundation2.palette.red.dark,
  alertColorTextInfo: foundation2.palette.blue.dark,
  alertColorTextSuccess: foundation2.palette.green.dark,
  alertColorTextWarning: foundation2.palette.orange.dark,
  alertColorTextLink: foundation2.palette.ink.normal,
  badgeBorderRadius: "12px",
  badgeBundleBasicBackground: "linear-gradient(to top right, #E13E3B 0%, #E87E09 100%)",
  badgeBundleBasicForeground: foundation2.palette.white.normal,
  badgeBundleMediumBackground: "linear-gradient(to top right, #3719AB 0%, #8539DB 100%)",
  badgeBundleMediumForeground: foundation2.palette.white.normal,
  badgeBundleTopBackground: "linear-gradient(to top right, #2D2D2E 0%, #696E73 100%)",
  badgeBundleTopForeground: foundation2.palette.white.normal,
  badgeCriticalBackground: foundation2.palette.red.normal,
  badgeCriticalForeground: foundation2.palette.white.normal,
  badgeCriticalSubtleBackground: foundation2.palette.red.light,
  badgeCriticalSubtleForeground: foundation2.palette.red.dark,
  badgeDarkBackground: foundation2.palette.ink.dark,
  badgeDarkForeground: foundation2.palette.white.normal,
  badgeInfoBackground: foundation2.palette.blue.normal,
  badgeInfoForeground: foundation2.palette.white.normal,
  badgeInfoSubtleBackground: foundation2.palette.blue.light,
  badgeInfoSubtleForeground: foundation2.palette.blue.dark,
  badgeNeutralBackground: foundation2.palette.cloud.light,
  badgeNeutralForeground: foundation2.palette.ink.dark,
  badgeSuccessBackground: foundation2.palette.green.normal,
  badgeSuccessForeground: foundation2.palette.white.normal,
  badgeSuccessSubtleBackground: foundation2.palette.green.light,
  badgeSuccessSubtleForeground: foundation2.palette.green.dark,
  badgeWarningBackground: foundation2.palette.orange.normal,
  badgeWarningForeground: foundation2.palette.white.normal,
  badgeWarningSubtleBackground: foundation2.palette.orange.light,
  badgeWarningSubtleForeground: foundation2.palette.orange.dark,
  badgeWhiteBackground: foundation2.palette.white.normal,
  badgeWhiteForeground: foundation2.palette.ink.dark,
  borderRadiusBadge: "12px",
  marginBadgeIcon: `0 ${foundation2.space.XXSmall} 0 0`,
  marginButtonGroup: `0 1px 0 0`,
  marginButtonIcon: foundation2.space.XSmall,
  buttonLinkCriticalBackground: "transparent",
  buttonLinkCriticalBackgroundHover: foundation2.palette.red.lightHover,
  buttonLinkCriticalBackgroundActive: foundation2.palette.red.lightActive,
  buttonLinkCriticalForeground: foundation2.palette.red.normal,
  buttonLinkCriticalForegroundHover: foundation2.palette.red.normalHover,
  buttonLinkCriticalForegroundActive: foundation2.palette.red.normalActive,
  buttonLinkPrimaryBackground: "transparent",
  buttonLinkPrimaryBackgroundHover: foundation2.palette.product.lightHover,
  buttonLinkPrimaryBackgroundActive: foundation2.palette.product.lightActive,
  buttonLinkPrimaryForeground: foundation2.palette.product.normal,
  buttonLinkPrimaryForegroundHover: foundation2.palette.product.darkHover,
  buttonLinkPrimaryForegroundActive: foundation2.palette.product.darkActive,
  buttonLinkSecondaryBackground: "transparent",
  buttonLinkSecondaryBackgroundHover: foundation2.palette.cloud.lightHover,
  buttonLinkSecondaryBackgroundActive: foundation2.palette.cloud.lightActive,
  buttonLinkSecondaryForeground: foundation2.palette.ink.dark,
  buttonLinkSecondaryForegroundHover: foundation2.palette.ink.darkHover,
  buttonLinkSecondaryForegroundActive: foundation2.palette.ink.darkActive,
  buttonBundleBasicBackground: "linear-gradient(to top right, #E13E3B 0%, #E87E09 100%)",
  buttonBundleBasicBackgroundHover: "linear-gradient(to top right, #BD2825 0%, #D67000 100%)",
  buttonBundleBasicBackgroundActive: "linear-gradient(to top right, #9F1816 0%, #C36802 100%)",
  buttonBundleMediumBackground: "linear-gradient(to top right, #3719AB 0%, #8539DB 100%)",
  buttonBundleMediumBackgroundHover: "linear-gradient(to top right, #2D1393 0%, #7343AA 100%)",
  buttonBundleMediumBackgroundActive: "linear-gradient(to top right, #250F79 0%, #5A3485 100%)",
  buttonBundleTopBackground: "linear-gradient(to top right, #2D2D2E 0%, #696E73 100%)",
  buttonBundleTopBackgroundHover: "linear-gradient(to top right, #171718 0%, #51575C 100%)",
  buttonBundleTopBackgroundActive: "linear-gradient(to top right, #101011 0%, #51575C)",
  buttonCriticalSubtleBackground: foundation2.palette.red.light,
  buttonCriticalSubtleBackgroundHover: foundation2.palette.red.lightHover,
  buttonCriticalSubtleBackgroundActive: foundation2.palette.red.lightActive,
  buttonCriticalSubtleForeground: foundation2.palette.red.dark,
  buttonCriticalSubtleForegroundHover: foundation2.palette.red.darkHover,
  buttonCriticalSubtleForegroundActive: foundation2.palette.red.darkActive,
  buttonCriticalBackground: foundation2.palette.red.normal,
  buttonCriticalBackgroundHover: foundation2.palette.red.normalHover,
  buttonCriticalBackgroundActive: foundation2.palette.red.normalActive,
  buttonCriticalForeground: foundation2.palette.white.normal,
  buttonCriticalForegroundHover: foundation2.palette.white.normal,
  buttonCriticalForegroundActive: foundation2.palette.white.normal,
  buttonSmallFontSize: foundation2.fontSize.small,
  buttonSmallPadding: `0 ${foundation2.space.small}`,
  buttonSmallBothIconsPadding: `0 ${foundation2.space.XSmall}`,
  buttonSmallLeftIconPadding: `0 ${foundation2.space.small} 0 ${foundation2.space.XSmall}`,
  buttonSmallRightIconPadding: `0 ${foundation2.space.XSmall} 0 ${foundation2.space.small}`,
  buttonNormalFontSize: foundation2.fontSize.normal,
  buttonNormalPadding: `0 ${foundation2.space.medium}`,
  buttonNormalBothIconsPadding: `0 ${foundation2.space.small}`,
  buttonNormalLeftIconPadding: `0 ${foundation2.space.medium} 0 ${foundation2.space.small}`,
  buttonNormalRightIconPadding: `0 ${foundation2.space.small} 0 ${foundation2.space.medium}`,
  buttonLargeFontSize: foundation2.fontSize.large,
  buttonLargePadding: `0 28px`,
  buttonLargeBothIconsPadding: `0 ${foundation2.space.medium}`,
  buttonLargeLeftIconPadding: `0 28px 0 ${foundation2.space.medium}`,
  buttonLargeRightIconPadding: `0 ${foundation2.space.medium} 0 28px`,
  buttonInfoBackground: foundation2.palette.blue.normal,
  buttonInfoBackgroundHover: foundation2.palette.blue.normalHover,
  buttonInfoBackgroundActive: foundation2.palette.blue.normalActive,
  buttonInfoForeground: foundation2.palette.white.normal,
  buttonInfoForegroundHover: foundation2.palette.white.normal,
  buttonInfoForegroundActive: foundation2.palette.white.normal,
  buttonWithoutTextPadding: "0",
  buttonPaddingXSmall: foundation2.space["200"],
  buttonPaddingSmall: foundation2.space["300"],
  buttonPaddingNormal: foundation2.space["400"],
  buttonPaddingLarge: "28px",
  buttonPrimarySubtleBackground: foundation2.palette.product.light,
  buttonPrimarySubtleBackgroundHover: foundation2.palette.product.lightHover,
  buttonPrimarySubtleBackgroundActive: foundation2.palette.product.lightActive,
  buttonPrimarySubtleForeground: foundation2.palette.product.dark,
  buttonPrimarySubtleForegroundHover: foundation2.palette.product.darkHover,
  buttonPrimarySubtleForegroundActive: foundation2.palette.product.darkActive,
  buttonPrimaryBackground: foundation2.palette.product.normal,
  buttonPrimaryBackgroundHover: foundation2.palette.product.normalHover,
  buttonPrimaryBackgroundActive: foundation2.palette.product.normalActive,
  buttonPrimaryForeground: foundation2.palette.white.normal,
  buttonPrimaryForegroundHover: foundation2.palette.white.normal,
  buttonPrimaryForegroundActive: foundation2.palette.white.normal,
  buttonPrimaryBorderColorFocus: transparentColor_default(foundation2.palette.product.normal, 50),
  buttonSecondaryBackground: foundation2.palette.cloud.normal,
  buttonSecondaryBackgroundHover: foundation2.palette.cloud.normalHover,
  buttonSecondaryBackgroundActive: foundation2.palette.cloud.normalActive,
  buttonSecondaryForeground: foundation2.palette.ink.dark,
  buttonSecondaryForegroundHover: foundation2.palette.ink.darkHover,
  buttonSecondaryForegroundActive: foundation2.palette.ink.darkActive,
  buttonSuccessBackground: foundation2.palette.green.normal,
  buttonSuccessBackgroundHover: foundation2.palette.green.normalHover,
  buttonSuccessBackgroundActive: foundation2.palette.green.normalActive,
  buttonSuccessForeground: foundation2.palette.white.normal,
  buttonSuccessForegroundHover: foundation2.palette.white.normal,
  buttonSuccessForegroundActive: foundation2.palette.white.normal,
  buttonWarningBackground: foundation2.palette.orange.normal,
  buttonWarningBackgroundHover: foundation2.palette.orange.normalHover,
  buttonWarningBackgroundActive: foundation2.palette.orange.normalActive,
  buttonWarningForeground: foundation2.palette.white.normal,
  buttonWarningForegroundHover: foundation2.palette.white.normal,
  buttonWarningForegroundActive: foundation2.palette.white.normal,
  buttonWhiteBackground: foundation2.palette.white.normal,
  buttonWhiteBackgroundHover: foundation2.palette.cloud.light,
  buttonWhiteBackgroundActive: foundation2.palette.cloud.lightHover,
  buttonWhiteForeground: foundation2.palette.ink.dark,
  buttonWhiteForegroundHover: foundation2.palette.ink.darkHover,
  buttonWhiteForegroundActive: foundation2.palette.ink.darkActive,
  buttonWhiteBorderColorFocus: transparentColor_default(foundation2.palette.white.normalActive, 50),
  boxShadowButtonFocus: boxShadow_default([
    {
      def: ["0", "0", "4px", "1px"],
      color: transparentColor_default(foundation2.palette.blue.normal, 30),
      inset: false
    }
  ]),
  borderColorButtonPrimaryBordered: foundation2.palette.product.normal,
  borderColorButtonPrimaryBorderedHover: foundation2.palette.product.normalHover,
  borderColorButtonPrimaryBorderedActive: foundation2.palette.product.normalActive,
  borderColorButtonSecondaryBordered: foundation2.palette.ink.dark,
  borderColorButtonSecondaryBorderedHover: foundation2.palette.ink.darkHover,
  borderColorButtonSecondaryBorderedActive: foundation2.palette.ink.darkActive,
  borderColorButtonFacebookBordered: foundation2.palette.social.facebook,
  borderColorButtonFacebookBorderedHover: foundation2.palette.social.facebookHover,
  borderColorButtonFacebookBorderedActive: foundation2.palette.social.facebookActive,
  borderColorButtonGoogleBordered: foundation2.palette.ink.dark,
  borderColorButtonGoogleBorderedHover: foundation2.palette.ink.darkHover,
  borderColorButtonGoogleBorderedActive: foundation2.palette.ink.darkActive,
  borderColorButtonInfoBordered: foundation2.palette.blue.normal,
  borderColorButtonInfoBorderedHover: foundation2.palette.blue.normalHover,
  borderColorButtonInfoBorderedActive: foundation2.palette.blue.normalActive,
  borderColorButtonSuccessBordered: foundation2.palette.green.normal,
  borderColorButtonSuccessBorderedHover: foundation2.palette.green.normalHover,
  borderColorButtonSuccessBorderedActive: foundation2.palette.green.normalActive,
  borderColorButtonWarningBordered: foundation2.palette.orange.normal,
  borderColorButtonWarningBorderedHover: foundation2.palette.orange.normalHover,
  borderColorButtonWarningBorderedActive: foundation2.palette.orange.normalActive,
  borderColorButtonCriticalBordered: foundation2.palette.red.normal,
  borderColorButtonCriticalBorderedHover: foundation2.palette.red.normalHover,
  borderColorButtonCriticalBorderedActive: foundation2.palette.red.normalActive,
  borderColorButtonWhiteBordered: foundation2.palette.white.normal,
  borderColorButtonWhiteBorderedHover: foundation2.palette.white.normal,
  borderColorButtonWhiteBorderedActive: foundation2.palette.white.normal,
  borderColorCard: foundation2.palette.cloud.normal,
  borderColorCheckboxRadio: foundation2.palette.cloud.dark,
  borderColorCheckboxRadioActive: foundation2.palette.ink.dark,
  borderColorCheckboxRadioError: foundation2.palette.red.normal,
  borderColorCheckboxRadioFocus: foundation2.palette.blue.normal,
  borderColorCheckboxRadioHover: foundation2.palette.ink.normal,
  borderColorInput: foundation2.palette.cloud.dark,
  borderColorInputActive: foundation2.palette.cloud.darkActive,
  borderColorInputError: foundation2.palette.red.normal,
  borderColorInputErrorFocus: foundation2.palette.red.normal,
  borderColorInputErrorHover: foundation2.palette.red.normalHover,
  borderColorInputFocus: foundation2.palette.blue.normal,
  borderColorInputHover: foundation2.palette.cloud.darkHover,
  borderColorModal: foundation2.palette.cloud.normal,
  borderColorTable: foundation2.palette.cloud.normal,
  borderColorTableCell: foundation2.palette.cloud.dark,
  borderColorTableHead: foundation2.palette.cloud.dark,
  borderColorTag: foundation2.palette.cloud.dark,
  borderColorTagFocus: foundation2.palette.blue.normal,
  modifierScaleButtonActive: 0.95,
  modifierScaleCheckboxRadioActive: 0.95,
  fontSizeButtonSmall: foundation2.fontSize.small,
  fontSizeButtonNormal: foundation2.fontSize.normal,
  fontSizeButtonLarge: foundation2.fontSize.large,
  fontSizeInputSmall: foundation2.fontSize.normal,
  fontSizeInputNormal: foundation2.fontSize.normal,
  fontSizeFormFeedback: foundation2.fontSize.small,
  fontSizeFormLabel: foundation2.fontSize.normal,
  fontSizeMessage: foundation2.fontSize.normal,
  borderStyleCard: "solid",
  borderStyleInput: "solid",
  borderWidthCard: "1px",
  borderWidthInput: "1px",
  borderWidthInputFocus: "2px",
  widthCarrierLogo: foundation2.size.large,
  widthIconSmall: foundation2.size.small,
  widthIconMedium: "20px",
  widthIconLarge: "24px",
  widthBadgeCircled: "24px",
  widthCheckbox: "20px",
  widthRadioButton: "20px",
  widthStopoverArrow: "36px",
  widthCountryFlag: "24px",
  widthModalSmall: "540px",
  widthModalNormal: "740px",
  widthModalLarge: "900px",
  widthModalExtraLarge: "1280px",
  heightCarrierLogo: foundation2.size.large,
  heightButtonSmall: foundation2.size.large,
  heightButtonNormal: foundation2.size.extraLarge,
  heightButtonLarge: foundation2.size.extraExtraLarge,
  heightInputSmall: foundation2.size.large,
  heightInputNormal: foundation2.size.extraLarge,
  heightInputLarge: foundation2.size.extraExtraLarge,
  heightIconSmall: foundation2.size.small,
  heightIconMedium: "20px",
  heightIconLarge: "24px",
  heightBadge: "24px",
  heightCheckbox: "20px",
  heightRadioButton: "20px",
  heightStopoverArrow: "7px",
  heightCountryFlag: "auto",
  heightServiceLogoSmall: "12px",
  heightServiceLogoMedium: "24px",
  heightServiceLogoLarge: "48px",
  heightSeparator: "1px",
  heightInputGroupSeparatorSmall: foundation2.size.small,
  heightInputGroupSeparatorNormal: "20px",
  heightIllustrationSmall: "90px",
  heightIllustrationMedium: "200px",
  countryFlagShadow: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.ink.dark, 10),
      inset: true
    }
  ]),
  countryFlagBackground: "transparent",
  countryFlagSmallHeight: "9px",
  countryFlagSmallWidth: "16px",
  countryFlagMediumHeight: "13px",
  countryFlagMediumWidth: "24px",
  dialogBorderRadiusDesktop: "9px",
  dialogBorderRadiusMobile: "12px",
  dialogWidth: "540px",
  drawerOverlayBackground: transparentColor_default(foundation2.palette.ink.dark, 50),
  formBoxSmallHeight: foundation2.size.large,
  formBoxNormalHeight: foundation2.size.extraLarge,
  formBoxLargeHeight: foundation2.size.extraExtraLarge,
  formElementBackground: foundation2.palette.white.normal,
  formElementDisabledBackground: foundation2.palette.cloud.normal,
  formElementDisabledForeground: foundation2.palette.ink.normal,
  formElementDisabledOpacity: "0.5",
  formElementBorderColorDisabled: "transparent",
  formElementBorderColor: foundation2.palette.cloud.dark,
  formElementBorderColorHover: foundation2.palette.cloud.darkHover,
  formElementBorderColorActive: foundation2.palette.cloud.darkActive,
  formElementBorderColorFocus: foundation2.palette.blue.normal,
  formElementBorderColorError: foundation2.palette.red.normal,
  formElementBorderColorErrorHover: foundation2.palette.red.normalHover,
  formElementBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.cloud.dark, 100),
      inset: true
    }
  ]),
  formElementBoxShadowError: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.red.normal, 100),
      inset: true
    }
  ]),
  formElementBoxShadowErrorHover: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.red.normalHover, 100),
      inset: true
    }
  ]),
  formElementBoxShadowHover: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.cloud.darkHover, 100),
      inset: true
    }
  ]),
  formElementFocusBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.blue.normal, 100),
      inset: true
    },
    {
      def: ["0", "0", "0", "3px"],
      color: transparentColor_default(foundation2.palette.blue.normal, 15),
      inset: true
    }
  ]),
  formElementErrorFocusBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.red.normal, 100),
      inset: true
    },
    {
      def: ["0", "0", "0", "3px"],
      color: transparentColor_default(foundation2.palette.red.normal, 15),
      inset: true
    }
  ]),
  formElementNormalFontSize: foundation2.fontSize.normal,
  formElementNormalPadding: `0 ${foundation2.space["300"]}`,
  formElementForeground: foundation2.palette.ink.light,
  formElementFilledForeground: foundation2.palette.ink.dark,
  formElementLabelForeground: foundation2.palette.ink.dark,
  formElementLabelFilledForeground: foundation2.palette.ink.normal,
  formElementPrefixForeground: foundation2.palette.ink.normal,
  formElementSmallPadding: `0 ${foundation2.space["300"]}`,
  marginTopFormFeedback: foundation2.space.XXXSmall,
  opacityCheckboxDisabled: "0.5",
  opacityRadioButtonDisabled: "0.5",
  headingDisplayFontSize: "40px",
  headingDisplayFontWeight: foundation2.fontWeight.bold,
  headingDisplayLineHeight: "44px",
  headingDisplaySubtitleFontSize: "22px",
  headingDisplaySubtitleFontWeight: foundation2.fontWeight.normal,
  headingDisplaySubtitleLineHeight: "28px",
  headingTitle0FontSize: "28px",
  headingTitle0FontWeight: foundation2.fontWeight.bold,
  headingTitle0LineHeight: "36px",
  headingTitle1FontSize: "24px",
  headingTitle1FontWeight: foundation2.fontWeight.bold,
  headingTitle1LineHeight: "32px",
  headingTitle2FontSize: "20px",
  headingTitle2FontWeight: foundation2.fontWeight.bold,
  headingTitle2LineHeight: "28px",
  headingTitle3FontSize: foundation2.fontSize.extraLarge,
  headingTitle3FontWeight: foundation2.fontWeight.bold,
  headingTitle3LineHeight: "24px",
  headingTitle4FontSize: foundation2.fontSize.large,
  headingTitle4FontWeight: foundation2.fontWeight.bold,
  headingTitle4LineHeight: "22px",
  headingTitle5FontSize: foundation2.fontSize.normal,
  headingTitle5FontWeight: foundation2.fontWeight.bold,
  headingTitle5LineHeight: "20px",
  headingTitle6FontSize: foundation2.fontSize.small,
  headingTitle6FontWeight: foundation2.fontWeight.bold,
  headingTitle6LineHeight: "18px",
  headingForeground: foundation2.palette.ink.dark,
  headingForegroundInverted: foundation2.palette.white.normal,
  iconPrimaryForeground: foundation2.palette.ink.dark,
  iconSecondaryForeground: foundation2.palette.ink.normal,
  iconTertiaryForeground: foundation2.palette.cloud.dark,
  iconInfoForeground: foundation2.palette.blue.normal,
  iconSuccessForeground: foundation2.palette.green.normal,
  iconWarningForeground: foundation2.palette.orange.normal,
  iconCriticalForeground: foundation2.palette.red.normal,
  iconSmallSize: "16px",
  iconMediumSize: "20px",
  iconLargeSize: "24px",
  iconExtraLargeSize: "32px",
  illustrationExtraSmallHeight: "90px",
  illustrationSmallHeight: "120px",
  illustrationMediumHeight: "200px",
  illustrationLargeHeight: "280px",
  illustrationDisplayHeight: "460px",
  loadingForeground: foundation2.palette.cloud.dark,
  modalBorderRadiusMobile: "12px",
  modalBorderRadius: "12px",
  modalExtraSmallWidth: "360px",
  modalSmallWidth: "540px",
  modalNormalWidth: "740px",
  modalLargeWidth: "900px",
  modalExtraLargeWidth: "1280px",
  marginBottomSelectSuffix: foundation2.space.XXXSmall,
  paddingLeftSelectPrefix: "48px",
  socialButtonAppleBackground: foundation2.palette.ink.normal,
  socialButtonAppleBackgroundHover: foundation2.palette.ink.normalHover,
  socialButtonAppleBackgroundActive: foundation2.palette.ink.normalActive,
  socialButtonAppleForeground: foundation2.palette.white.normal,
  socialButtonAppleForegroundHover: foundation2.palette.white.normal,
  socialButtonAppleForegroundActive: foundation2.palette.white.normal,
  socialButtonAppleBorderColorFocus: transparentColor_default(foundation2.palette.ink.light, 50),
  socialButtonAppleIconForeground: foundation2.palette.white.normal,
  socialButtonFacebookBackground: foundation2.palette.social.facebook,
  socialButtonFacebookBackgroundHover: foundation2.palette.social.facebookHover,
  socialButtonFacebookBackgroundActive: foundation2.palette.social.facebookActive,
  socialButtonFacebookForeground: foundation2.palette.white.normal,
  socialButtonFacebookForegroundHover: foundation2.palette.white.normal,
  socialButtonFacebookForegroundActive: foundation2.palette.white.normal,
  socialButtonFacebookIconForeground: "#3B5998",
  socialButtonGoogleBackground: foundation2.palette.cloud.light,
  socialButtonGoogleBackgroundHover: foundation2.palette.cloud.lightHover,
  socialButtonGoogleBackgroundActive: foundation2.palette.cloud.lightActive,
  socialButtonGoogleForeground: foundation2.palette.ink.dark,
  socialButtonGoogleForegroundHover: foundation2.palette.ink.darkHover,
  socialButtonGoogleForegroundActive: foundation2.palette.ink.darkActive,
  socialButtonGoogleIconForeground: "currentColor",
  socialButtonTwitterBackground: foundation2.palette.cloud.dark,
  socialButtonTwitterBackgroundHover: foundation2.palette.cloud.normalHover,
  socialButtonTwitterBackgroundActive: foundation2.palette.cloud.normalActive,
  socialButtonTwitterForeground: foundation2.palette.ink.normal,
  socialButtonTwitterForegroundHover: foundation2.palette.ink.normal,
  socialButtonTwitterForegroundActive: foundation2.palette.ink.normal,
  socialButtonTwitterBorderColorFocus: transparentColor_default(foundation2.palette.ink.light, 50),
  socialButtonTwitterIconForeground: "#00ACEE",
  tabBundleBasicForeground: "linear-gradient(80.91deg, #E13E3B 0%, #E87E09 100%)",
  tabBundleBasicBackground: foundation2.palette.white.normal,
  tabBundleBasicBackgroundHover: "rgba(215, 51, 28, 0.08)",
  tabBundleBasicBackgroundActive: "rgba(215, 51, 28, 0.12)",
  tabBundleMediumForeground: "linear-gradient(80.91deg, #3719AB  0%, #8539DB 100%)",
  tabBundleMediumBackground: foundation2.palette.white.normal,
  tabBundleMediumBackgroundHover: "rgba(59, 30, 176, 0.08)",
  tabBundleMediumBackgroundActive: "rgba(59, 30, 176, 0.12)",
  tabBundleTopBackground: foundation2.palette.white.normal,
  tabBundleTopBackgroundHover: foundation2.palette.white.normalHover,
  tabBundleTopForeground: "linear-gradient(80.91deg, #2D2D2E 0%, #696E73 100%)",
  fontWeightTableHead: foundation2.fontWeight.bold,
  fontWeightLinks: foundation2.fontWeight.medium,
  tagColoredBackground: foundation2.palette.blue.light,
  tagColoredBackgroundHover: foundation2.palette.blue.lightHover,
  tagColoredBackgroundActive: foundation2.palette.blue.lightActive,
  tagColoredForeground: foundation2.palette.blue.darker,
  tagNeutralBackground: foundation2.palette.cloud.normal,
  tagNeutralBackgroundHover: foundation2.palette.cloud.normalHover,
  tagNeutralBackgroundActive: foundation2.palette.cloud.normalActive,
  tagNeutralForeground: foundation2.palette.ink.dark,
  textLinkPrimaryForeground: foundation2.palette.product.dark,
  textLinkPrimaryForegroundHover: foundation2.palette.product.darkHover,
  textLinkPrimaryForegroundActive: foundation2.palette.product.darkActive,
  textLinkSecondaryForeground: foundation2.palette.ink.dark,
  textLinkSecondaryForegroundHover: foundation2.palette.product.darkHover,
  textLinkSecondaryForegroundActive: foundation2.palette.product.darkActive,
  textLinkSuccessForeground: foundation2.palette.green.dark,
  textLinkSuccessForegroundHover: foundation2.palette.green.darkHover,
  textLinkSuccessForegroundActive: foundation2.palette.green.darker,
  textLinkInfoForeground: foundation2.palette.blue.dark,
  textLinkInfoForegroundHover: foundation2.palette.blue.darkHover,
  textLinkInfoForegroundActive: foundation2.palette.blue.darker,
  textLinkWarningForeground: foundation2.palette.orange.dark,
  textLinkWarningForegroundHover: foundation2.palette.orange.darkHover,
  textLinkWarningForegroundActive: foundation2.palette.orange.darker,
  textLinkCriticalForeground: foundation2.palette.red.dark,
  textLinkCriticalForegroundHover: foundation2.palette.red.darkHover,
  textLinkCriticalForegroundActive: foundation2.palette.red.darker,
  textLinkWhiteForeground: foundation2.palette.white.normal,
  textLinkWhiteForegroundHover: foundation2.palette.product.light,
  textLinkWhiteForegroundActive: foundation2.palette.product.light,
  textDecorationTextLinkPrimary: "underline",
  textDecorationTextLinkPrimaryHover: "none",
  textDecorationTextLinkSecondary: "underline",
  textDecorationTextLinkSecondaryHover: "none",
  textPrimaryBackground: transparentColor_default(foundation2.palette.ink.dark, 10),
  textPrimaryForeground: foundation2.palette.ink.dark,
  textSecondaryBackground: transparentColor_default(foundation2.palette.ink.normal, 10),
  textSecondaryForeground: foundation2.palette.ink.normal,
  textInfoBackground: transparentColor_default(foundation2.palette.blue.normal, 10),
  textInfoForeground: foundation2.palette.blue.normal,
  textSuccessBackground: transparentColor_default(foundation2.palette.green.normal, 10),
  textSuccessForeground: foundation2.palette.green.normal,
  textWarningBackground: transparentColor_default(foundation2.palette.orange.normal, 10),
  textWarningForeground: foundation2.palette.orange.normal,
  textCriticalBackground: transparentColor_default(foundation2.palette.red.normal, 10),
  textCriticalForeground: foundation2.palette.red.normal,
  textWhiteBackground: transparentColor_default(foundation2.palette.white.normal, 10),
  textWhiteForeground: foundation2.palette.white.normal,
  backgroundIllustration: "transparent",
  borderRadius50: foundation2.borderRadius["50"],
  borderRadius100: foundation2.borderRadius["100"],
  borderRadius150: foundation2.borderRadius["150"],
  borderRadius300: foundation2.borderRadius["300"],
  borderRadius400: foundation2.borderRadius["400"],
  borderRadiusCircle: foundation2.borderRadius.circle,
  borderRadiusSmall: foundation2.borderRadius.small,
  borderRadiusNormal: foundation2.borderRadius.normal,
  borderRadiusLarge: foundation2.borderRadius.large,
  borderRadiusNone: foundation2.borderRadius.none,
  borderRadiusFull: foundation2.borderRadius.full,
  breakpointMediumMobile: foundation2.breakpoint.mediumMobile,
  breakpointLargeMobile: foundation2.breakpoint.largeMobile,
  breakpointTablet: foundation2.breakpoint.tablet,
  breakpointDesktop: foundation2.breakpoint.desktop,
  breakpointLargeDesktop: foundation2.breakpoint.largeDesktop,
  widthBreakpointMediumMobile: foundation2.breakpoint.mediumMobile,
  widthBreakpointLargeMobile: foundation2.breakpoint.largeMobile,
  widthBreakpointTablet: foundation2.breakpoint.tablet,
  widthBreakpointDesktop: foundation2.breakpoint.desktop,
  widthBreakpointLargeDesktop: foundation2.breakpoint.largeDesktop,
  durationFast: "0.15s",
  durationNormal: "0.3s",
  durationSlow: "0.4s",
  elevationSuppressedBackground: foundation2.palette.cloud.light,
  elevationFlatBackground: foundation2.palette.white.normal,
  elevationFlatBorderColor: foundation2.palette.cloud.normal,
  elevationFlatBorderSize: "1px",
  elevationActionBackground: foundation2.palette.white.normal,
  elevationActionBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "2px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "1px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  elevationLevel1BoxShadow: boxShadow_default([
    {
      def: ["0", "0", "2px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "1px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  elevationActionActiveBackground: foundation2.palette.white.normal,
  elevationActionActiveBoxShadow: boxShadow_default([
    {
      def: ["0", "1px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  elevationLevel2BoxShadow: boxShadow_default([
    {
      def: ["0", "1px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  elevationFixedBackground: foundation2.palette.white.normal,
  elevationFixedBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "2px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "2px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  elevationFixedReverseBackground: foundation2.palette.white.normal,
  elevationFixedReverseBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "2px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "-2px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  elevationRaisedBackground: foundation2.palette.white.normal,
  elevationRaisedBoxShadow: boxShadow_default([
    {
      def: ["0", "4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "8px", "24px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    }
  ]),
  elevationLevel3BoxShadow: boxShadow_default([
    {
      def: ["0", "4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "8px", "24px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    }
  ]),
  elevationRaisedReverseBackground: foundation2.palette.white.normal,
  elevationRaisedReverseBoxShadow: boxShadow_default([
    {
      def: ["0", "-4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "-8px", "24px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    }
  ]),
  elevationLevel3ReverseBoxShadow: boxShadow_default([
    {
      def: ["0", "-4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "-8px", "24px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    }
  ]),
  elevationOverlayBackground: foundation2.palette.white.normal,
  elevationOverlayBoxShadow: boxShadow_default([
    {
      def: ["0", "12px", "24px", "-4px"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    },
    {
      def: ["0", "8px", "60px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 32),
      inset: false
    }
  ]),
  elevationLevel4BoxShadow: boxShadow_default([
    {
      def: ["0", "12px", "24px", "-4px"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    },
    {
      def: ["0", "8px", "60px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 32),
      inset: false
    }
  ]),
  backgroundBody: foundation2.palette.cloud.light,
  boxShadowAction: boxShadow_default([
    {
      def: ["0", "0", "2px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "1px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  boxShadowActionActive: boxShadow_default([
    {
      def: ["0", "1px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  boxShadowFixed: boxShadow_default([
    {
      def: ["0", "0", "2px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "2px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  boxShadowFixedReverse: boxShadow_default([
    {
      def: ["0", "0", "2px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "-2px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  boxShadowOverlay: boxShadow_default([
    {
      def: ["0", "12px", "24px", "-4px"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    },
    {
      def: ["0", "8px", "60px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 32),
      inset: false
    }
  ]),
  boxShadowRaised: boxShadow_default([
    {
      def: ["0", "4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "8px", "24px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    }
  ]),
  boxShadowRaisedReverse: boxShadow_default([
    {
      def: ["0", "-4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "-8px", "24px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    }
  ]),
  opacityOverlay: "0.8",
  paletteBlueLight: foundation2.palette.blue.light,
  paletteBlueLightHover: foundation2.palette.blue.lightHover,
  paletteBlueLightActive: foundation2.palette.blue.lightActive,
  paletteBlueNormal: foundation2.palette.blue.normal,
  paletteBlueNormalHover: foundation2.palette.blue.normalHover,
  paletteBlueNormalActive: foundation2.palette.blue.normalActive,
  paletteBlueDark: foundation2.palette.blue.dark,
  paletteBlueDarkHover: foundation2.palette.blue.darkHover,
  paletteBlueDarkActive: foundation2.palette.blue.darkActive,
  paletteBlueDarker: foundation2.palette.blue.darker,
  paletteBundleBasic: foundation2.palette.bundle.basic,
  paletteBundleMedium: foundation2.palette.bundle.medium,
  paletteCloudLight: foundation2.palette.cloud.light,
  paletteCloudLightHover: foundation2.palette.cloud.lightHover,
  paletteCloudLightActive: foundation2.palette.cloud.lightActive,
  paletteCloudNormal: foundation2.palette.cloud.normal,
  paletteCloudNormalHover: foundation2.palette.cloud.normalHover,
  paletteCloudNormalActive: foundation2.palette.cloud.normalActive,
  paletteCloudDark: foundation2.palette.cloud.dark,
  paletteCloudDarkHover: foundation2.palette.cloud.darkHover,
  paletteCloudDarkActive: foundation2.palette.cloud.darkActive,
  paletteGreenLight: foundation2.palette.green.light,
  paletteGreenLightHover: foundation2.palette.green.lightHover,
  paletteGreenLightActive: foundation2.palette.green.lightActive,
  paletteGreenNormal: foundation2.palette.green.normal,
  paletteGreenNormalHover: foundation2.palette.green.normalHover,
  paletteGreenNormalActive: foundation2.palette.green.normalActive,
  paletteGreenDark: foundation2.palette.green.dark,
  paletteGreenDarkHover: foundation2.palette.green.darkHover,
  paletteGreenDarkActive: foundation2.palette.green.darkActive,
  paletteGreenDarker: foundation2.palette.green.darker,
  paletteInkDark: foundation2.palette.ink.dark,
  paletteInkDarkHover: foundation2.palette.ink.darkHover,
  paletteInkDarkActive: foundation2.palette.ink.darkActive,
  paletteInkLight: foundation2.palette.ink.light,
  paletteInkLightHover: foundation2.palette.ink.lightHover,
  paletteInkLightActive: foundation2.palette.ink.lightActive,
  paletteInkNormal: foundation2.palette.ink.normal,
  paletteInkNormalHover: foundation2.palette.ink.normalHover,
  paletteInkNormalActive: foundation2.palette.ink.normalActive,
  paletteOrangeLight: foundation2.palette.orange.light,
  paletteOrangeLightHover: foundation2.palette.orange.lightHover,
  paletteOrangeLightActive: foundation2.palette.orange.lightActive,
  paletteOrangeNormal: foundation2.palette.orange.normal,
  paletteOrangeNormalHover: foundation2.palette.orange.normalHover,
  paletteOrangeNormalActive: foundation2.palette.orange.normalActive,
  paletteOrangeDark: foundation2.palette.orange.dark,
  paletteOrangeDarkHover: foundation2.palette.orange.darkHover,
  paletteOrangeDarkActive: foundation2.palette.orange.darkActive,
  paletteOrangeDarker: foundation2.palette.orange.darker,
  paletteProductLight: foundation2.palette.product.light,
  paletteProductLightHover: foundation2.palette.product.lightHover,
  paletteProductLightActive: foundation2.palette.product.lightActive,
  paletteProductNormal: foundation2.palette.product.normal,
  paletteProductNormalHover: foundation2.palette.product.normalHover,
  paletteProductNormalActive: foundation2.palette.product.normalActive,
  paletteProductDark: foundation2.palette.product.dark,
  paletteProductDarkHover: foundation2.palette.product.darkHover,
  paletteProductDarkActive: foundation2.palette.product.darkActive,
  paletteProductDarker: foundation2.palette.product.darker,
  paletteRedLight: foundation2.palette.red.light,
  paletteRedLightHover: foundation2.palette.red.lightHover,
  paletteRedLightActive: foundation2.palette.red.lightActive,
  paletteRedNormal: foundation2.palette.red.normal,
  paletteRedNormalHover: foundation2.palette.red.normalHover,
  paletteRedNormalActive: foundation2.palette.red.normalActive,
  paletteRedDark: foundation2.palette.red.dark,
  paletteRedDarkHover: foundation2.palette.red.darkHover,
  paletteRedDarkActive: foundation2.palette.red.darkActive,
  paletteRedDarker: foundation2.palette.red.darker,
  paletteSocialFacebook: foundation2.palette.social.facebook,
  paletteSocialFacebookHover: foundation2.palette.social.facebookHover,
  paletteSocialFacebookActive: foundation2.palette.social.facebookActive,
  paletteWhite: foundation2.palette.white.normal,
  paletteWhiteNormal: foundation2.palette.white.normal,
  paletteWhiteHover: foundation2.palette.white.normalHover,
  paletteWhiteActive: foundation2.palette.white.normalActive,
  space50: foundation2.space["50"],
  space100: foundation2.space["100"],
  space150: foundation2.space["150"],
  space200: foundation2.space["200"],
  space300: foundation2.space["300"],
  space400: foundation2.space["400"],
  space500: foundation2.space["500"],
  space600: foundation2.space["600"],
  space800: foundation2.space["800"],
  space1000: foundation2.space["1000"],
  space1200: foundation2.space["1200"],
  space1600: foundation2.space["1600"],
  spaceXXXSmall: foundation2.space.XXXSmall,
  spaceXXSmall: foundation2.space.XXSmall,
  spaceXSmall: foundation2.space.XSmall,
  spaceSmall: foundation2.space.small,
  spaceMedium: foundation2.space.medium,
  spaceLarge: foundation2.space.large,
  spaceXLarge: foundation2.space.XLarge,
  spaceXXLarge: foundation2.space.XXLarge,
  spaceXXXLarge: foundation2.space.XXXLarge,
  fontFamily: foundation2.fontFamily.base,
  fontSizeTextSmall: foundation2.fontSize.small,
  fontSizeTextNormal: foundation2.fontSize.normal,
  fontSizeTextLarge: foundation2.fontSize.large,
  fontSizeTextExtraLarge: foundation2.fontSize.extraLarge,
  lineHeightText: "1.4",
  lineHeightSmall: foundation2.lineHeight.small,
  lineHeightNormal: foundation2.lineHeight.normal,
  lineHeightLarge: foundation2.lineHeight.large,
  lineHeightExtraLarge: foundation2.lineHeight.extraLarge,
  lineHeightTextSmall: foundation2.lineHeight.small,
  lineHeightTextNormal: foundation2.lineHeight.normal,
  lineHeightTextLarge: foundation2.lineHeight.large,
  lineHeightTextExtraLarge: foundation2.lineHeight.extraLarge,
  fontWeightNormal: foundation2.fontWeight.normal,
  fontWeightMedium: foundation2.fontWeight.medium,
  fontWeightBold: foundation2.fontWeight.bold,
  zIndexDefault: 1,
  zIndexSticky: 100,
  zIndexNavigationBar: 700,
  zIndexModalOverlay: 800,
  zIndexDrawer: 815,
  zIndexModal: 825,
  zIndexOnTheTop: 900
});
var createTokens_default = createTokens;

// src/utils/mergeDeep.ts
function mergeDeep(a, b) {
  const merged = { ...a };
  for (const key of Object.keys(b)) {
    if (b[key] instanceof Object) {
      if (a[key] instanceof Object) {
        merged[key] = mergeDeep(a[key], b[key]);
      } else {
        merged[key] = b[key];
      }
    } else {
      merged[key] = b[key];
    }
  }
  return merged;
}

// src/js/createTheme.ts
var createTheme = (foundation2 = {}, overrides = {}) => {
  const theme = mergeDeep(defaultFoundation_default, foundation2);
  return mergeDeep(createTokens_default(theme), overrides);
};
var createTheme_default = createTheme;

// src/fromPlainObject.ts
var fromPlainObject = (themePaletteColors) => {
  const theme = {
    palette: {
      product: {
        light: themePaletteColors.productLight,
        lightHover: themePaletteColors.productLightHover,
        lightActive: themePaletteColors.productLightActive,
        normal: themePaletteColors.productNormal,
        normalHover: themePaletteColors.productNormalHover,
        normalActive: themePaletteColors.productNormalActive,
        dark: themePaletteColors.productDark,
        darkHover: themePaletteColors.productDarkHover || defaultFoundation_default.palette.product.darkHover,
        darkActive: themePaletteColors.productDarkActive || defaultFoundation_default.palette.product.darkActive,
        darker: themePaletteColors.productDarker || defaultFoundation_default.palette.product.darker
      }
    }
  };
  return createTheme_default(theme);
};
var fromPlainObject_default = fromPlainObject;

// src/getTokens.ts
var getTokens = (foundation2 = {}) => {
  return createTheme_default(foundation2);
};
var getTokens_default = getTokens;

// src/tokensToCssVars.ts
var kebabCase = (str) => str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
var getCssVars = (object, prefix = `-`) => Object.entries(object).reduce((css, [key, value]) => {
  const newPrefix = kebabCase(`${prefix}-${key}`);
  if (typeof value !== "object")
    return `${css + newPrefix}:${value};`;
  return css + getCssVars(value, newPrefix);
}, ``);
var tokensToCssVars = ({
  tokens,
  cssClass
}) => {
  if (cssClass)
    return `${cssClass} {${getCssVars(tokens)}}`;
  return getCssVars(tokens);
};
var tokensToCssVars_default = tokensToCssVars;

// src/js/convertHexToRgba.ts
var convertHexToRgba = (color, opacity) => transparentColor_default(color, opacity);
var convertHexToRgba_default = convertHexToRgba;

// src/js/convertRgbaToHex.ts
var convertRgbaToHex = (rgba2) => {
  const rgbaRegex = /rgba?\((\d+), (\d+), (\d+),? ?(.*)?\)/;
  const [_, r, g, b, a] = rgba2.match(rgbaRegex) ?? [];
  const red2 = parseInt(r, 10).toString(16).padStart(2, "0");
  const green2 = parseInt(g, 10).toString(16).padStart(2, "0");
  const blue2 = parseInt(b, 10).toString(16).padStart(2, "0");
  const alpha = a ? Math.floor(Number(a) * 255).toString(16).padStart(2, "0") : "";
  return `#${red2}${green2}${blue2}${alpha && alpha !== "ff" ? alpha : ""}`;
};
var convertRgbaToHex_default = convertRgbaToHex;

// src/index.ts
var defaultTokens = createTheme_default();
var defaultTheme = createTheme_default();

export { convertHexToRgba_default as convertHexToRgba, convertRgbaToHex_default as convertRgbaToHex, createTheme_default as createTheme, defaultFoundation_default as defaultFoundation, defaultTheme, defaultTokens, fromPlainObject_default as fromPlainObject, getTokens_default as getTokens, tokensToCssVars_default as tokensToCssVars, transparentColor_default as transparentColor };
